import { BackButton } from '../../../components';

import './PortfolioError.css';

const PortfolioError = () => {
	return (
		<div className='error-comp portfolio-error'>
			<p>That portfolio category doesn't exist!</p>
			<BackButton
				bbClass='port-error-bb'
				bbTarget='/portfolio/'
				bbTextTarget='portfolio start' />
		</div>
	);
};

export default PortfolioError;
