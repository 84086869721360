import PortfolioGridItem from '../PortfolioGridItem/PortfolioGridItem';

import './PortfolioGrid.css';

const PortfolioGrid = ({ category, pagedSet, sortedKeys, viewerHandler }) => {

	/*
		This component now downgraded to being a simple output
	*/

	const pagedOutput = sortedKeys.map((item, index) => {
		return <PortfolioGridItem
			key={`thumb-${item}`}
			item={item}
			image={pagedSet[item].thumb}
			category={category}
			imageInfo={pagedSet[item].images[pagedSet[item].thumb].text}
			viewIndex={index}
			setViewIndex={viewerHandler} />;
	})

	return (
		<>
			<div className='portfolio-grid'>
				{pagedOutput}
			</div>
		</>
	);
};

export default PortfolioGrid;
