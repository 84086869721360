import { useState, useEffect } from 'react';

import { FaArrowUp } from 'react-icons/fa';

import './PortOutputScrollToTop.css';

const PortOutputScrollToTop = () => {
	/*
		Provides a button that will scroll the user to the top of the page

		Potentially janky timeout handling and state overriding each other
		Because of how frequently scroll events can fire
	*/
	const [disableReturnToTop, setDisableReturnToTop] = useState(true);
	const [scrollThrottle, setScrollThrottle] = useState(false);
	const [timeouter, setTimeouter] = useState();

	// Returns user to top of page and disables scrolling temporarily for smooth experience
	const returnToTopOnClick = () => {
		if (window.pageYOffset >= 300) {
			clearTimeout(timeouter);
			setScrollThrottle(true);
			window.scrollTo(0, 0);
			setTimeouter(() => {
				const newTimeout = setTimeout(() => {
					setScrollThrottle(false);
				}, 1500)
				return newTimeout;
			});
		};
	};

	// Potential jank due to JS event loop + html scroll event frequency
	useEffect(() => {
		// Enables button once page is scrolled a bit
		const handlePortScroll = () => {
			if (scrollThrottle === false) {
				setScrollThrottle(true);
				setDisableReturnToTop(window.pageYOffset < 300);
				setTimeouter(setTimeout(() => {
					setScrollThrottle(false);
				}, 50));
			}
		};
		window.addEventListener('scroll', handlePortScroll);
		return () => {
			window.removeEventListener('scroll', handlePortScroll)
		};
	}, [scrollThrottle]);

	return (
		<button
			className={`returnToTop${disableReturnToTop ? ' returnToTop-hidden' : ''}`}
			disabled={disableReturnToTop}
			onClick={returnToTopOnClick}>
			<FaArrowUp />
			<p>Return to top</p>
		</button>
	);
};

export default PortOutputScrollToTop;
