import { useState } from 'react';
import { getReviewData } from '../../../lib';
import ReviewItem from '../ReviewItem/ReviewItem';

import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import './ReviewCarousel.css';

const ReviewCarousel = () => {

	/*
		Eventually I want this to poll a backend that keeps a daily record of Yelp Reviews,
		but writing a whole other Lambda + security + dealing with the Yelp API is an ugh so that's on hold for now

		Buttons disable based on if there are more reviews in either direction
	*/

	const [activeIndex, setActiveIndex] = useState(0);
	const reviewsData = getReviewData();

	const incrementActive = (e) => {
		e.preventDefault();
		if (activeIndex < reviewsData.length - 1) { setActiveIndex(activeIndex + 1); };
	};

	const decrementActive = (e) => {
		e.preventDefault();
		if (activeIndex > 0) { setActiveIndex(activeIndex - 1); };
	};

	return (
		<div className='review-carousel'>
			<button
				onClick={decrementActive}
				disabled={activeIndex > 0 ? false : true}>
				<FaAngleLeft />
			</button>
			<ReviewItem
				key={`yelp-${reviewsData[activeIndex].name}`}
				review={reviewsData[activeIndex]} />
			<button
				onClick={incrementActive}
				disabled={activeIndex < reviewsData.length - 1 ? false : true}>
				<FaAngleRight />
			</button>
		</div>
	);
};

export default ReviewCarousel;
