import { ImageData } from "../../../assets";

const getCategoryText = (category) => {
	// Gets the info blurb for a category
	return {
		intro: ImageData[category].text.intro,
		taskList: ImageData[category].text.list
	};
};

const getPagedSet = ({ category, page }) => {
	/*
		Gets all the items for a category based on page
	*/
	let returner = {};
	Object.keys(ImageData[category].items).filter(item => {
		return ImageData[category].items[item].active === true
	}).slice(0, page).forEach(item => {
		returner[item] = ImageData[category].items[item]
	});
	return returner;
};

const getCategoryLength = (category) => {
	// Returns how many active items are in a category
	let count = 0;
	Object.keys(ImageData[category].items).forEach(item => {
		if (ImageData[category].items[item].active === true) {
			count += 1;
		}
	})
	return count;
};

const getActiveImages = ({ category, itemIndex }) => {
	/*
		Returns an array of images within an item within a category
		Only images marked active are returned
		Return array sorted by ascending key
	*/
	let returner = [];
	Object.keys(ImageData[category].items[itemIndex].images).filter(image => {
		return ImageData[category].items[itemIndex].images[image].active === true;
	}).sort((a, b) => { return parseInt(a) > parseInt(b) }).forEach(image => {
		returner.push(image);
	});
	return returner;
};

export {
	getCategoryText,
	getPagedSet,
	getCategoryLength,
	getActiveImages
};
