import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { RouteScrollToTop } from './components';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(<BrowserRouter>
	<RouteScrollToTop />
	<App />
</BrowserRouter>);
