import { useState } from 'react';
import LoadSpinner from '../LoadSpinner/LoadSpinner';

import './ImageLoader.css';

const ImageLoader = ({
	src,
	alt,
	className }) => {

	const [imgLoaded, setImgLoaded] = useState(false);

	const loadHandler = (e) => {
		setImgLoaded(true);
	};

	const classer = `${className !== undefined && className} ${imgLoaded ? 'img-loaded' : 'img-loading'}`;

	return (
		<div className='load-container'>
			{!imgLoaded && <LoadSpinner />}
			<img
				src={src}
				alt={alt}
				loading='lazy'
				className={classer}
				onLoad={loadHandler} />
		</div>
	);
};

export default ImageLoader;
