import { FaSearch } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { BackButton } from '../../../components';

import './PortfolioBrowse.css';

const PortfolioBrowse = () => {

	/*
		Perhaps extraneous usage of the links to routes but whatever
	*/

	return (
		<main className='portfolio-main'>
			<div className='browse-prompt'>
				<h1><FaSearch />Choose a category:</h1>
			</div>
			<ul className='browse-list'>
				<li>
					<NavLink
						className='browse-link'
						to='/portfolio/bathroom'>
						Bathroom
					</NavLink>
				</li>
				<li>
					<NavLink
						className='browse-link'
						to='/portfolio/interior'>
						Interior
					</NavLink>
				</li>
				<li>
					<NavLink
						className='browse-link'
						to='/portfolio/exterior'>
						Exterior
					</NavLink>
				</li>
			</ul>
			<BackButton bbClass='bb port-error-bb' bbTarget='/' bbTextTarget='homepage' />
		</main>
	);
};

export default PortfolioBrowse;
