import { Navbar } from '../components';
import { Outlet } from 'react-router-dom';

import './Portfolio.css';

const Portfolio = () => {

	/*
			Simply an outlet for the routes in the main app file
			<main> tag re-renders for each category
			Done to get rid of an extra div but perhaps can be rearranged
			WARNING: using a static <main> tag here enclosing the <Outlet> causes some weird bug
			React doesn't like how I would use <React.Fragment> in the portfolio grids when i do it like that
	*/

	return (
		<>
			<Navbar key='p-nav' portfolio />
			<Outlet />
		</>
	);
};

export default Portfolio;
