import { ImageData } from "../../../assets";

const getServiceImgInfo = () => {
	// Reads the thumb data from each category
	let returner = {};
	Object.keys(ImageData).forEach(category => {
		returner[category] = {
			"path": `../portfolio-images/${category}/${ImageData[category].thumb.item}/fulls/${ImageData[category].thumb.image}.jpg`,
			"alt": ImageData[category].items[ImageData[category].thumb.item].images[ImageData[category].thumb.image].text
		}
	})
	return returner;
};

export {
	getServiceImgInfo
};
