import { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { SmallIcon } from '../../assets';
import { BackButton } from '..';

import './Navbar.css';

const Navbar = ({ portfolio }) => {
	const [mobToggle, setMobToggle] = useState(false);
	const [prevY, setPrevY] = useState(0);
	const [scrolledDown, setScrolledDown] = useState(false);
	const [scrollThrottle, setScrollThrottle] = useState(false);
	const [shrunken, setShrunken] = useState(false)

	const mobToggleFalser = (e) => {
		setMobToggle(false);
	}

	const handleScroll = () => {
		if (scrollThrottle === false) {
			setScrollThrottle(true);
			if (!portfolio && window.pageYOffset > (window.innerHeight * 0.5)) { setShrunken(true) }
			else { setShrunken(false) };
			if (!portfolio && window.pageYOffset < (window.innerHeight * 1.5)) { }
			else if (window.pageYOffset < prevY) {
				setScrolledDown(false);
				setPrevY(window.pageYOffset);
			}
			else {
				setScrolledDown(true);
				setPrevY(window.pageYOffset);
			}
			setTimeout(() => {
				setScrollThrottle(false);
			}, 50);
		}
	};

	// Potential jank due to JS event loop + html scroll event frequency
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll)
		};
	}, [scrollThrottle, shrunken]);

	/*
		Mobile is a b
		Should look into whether a navbar toggle is possible without js, pure css
	*/

	const classer = `scroll-header${portfolio ? ' p-header' : ' h-header'}${scrolledDown ? ' scrolled-down' : ''}${shrunken ? ' shrunken' : ''}`

	return (
		<header className={classer}>
			<nav className={`${mobToggle ? 'mobile-nav' : ''}`}>
				{portfolio ?
					<>
						<BackButton bbClass='bb nav-bb' bbTarget='/' bbTextTarget='home' />
						<ul>
							<li className='nav-list-item'>
								<NavLink className={({ isActive }) => isActive ? 'nav-link nl-active' : 'nav-link nl-inactive'} to='/portfolio/bathroom' onClick={mobToggleFalser}>Bathroom</NavLink>
							</li>
							<li className='nav-list-item'>
								<NavLink className={({ isActive }) => isActive ? 'nav-link nl-active' : 'nav-link nl-inactive'} to='/portfolio/interior' onClick={mobToggleFalser}>Interior</NavLink>
							</li>
							<li className='nav-list-item'>
								<NavLink className={({ isActive }) => isActive ? 'nav-link nl-active' : 'nav-link nl-inactive'} to='/portfolio/exterior' onClick={mobToggleFalser}>Exterior</NavLink>
							</li>
						</ul>
					</> : <>
						<img src={SmallIcon} alt='Small BBGC Icon' className='homepage-nav-icon'></img>
						<ul>
							<li className='nav-list-item'>
								<a className='nav-link' href='#services' onClick={mobToggleFalser}>Services</a>
							</li>
							<li className='nav-list-item'>
								<a className='nav-link' href='#reviews' onClick={mobToggleFalser}>Reviews</a>
							</li>
							<li className='nav-list-item'>
								<a className='nav-link' href='#contact' onClick={mobToggleFalser}>Contact</a>
							</li>
							<li className='nav-list-item'>
								<Link className='nav-link portfolio-navbar-link' to='/portfolio' onClick={mobToggleFalser}>Portfolio</Link>
							</li>
						</ul>
					</>}
			</nav>
			<div className={`mob-toggle${mobToggle ? ' toggle-hide' : ''}`} onClick={(e) => {
				e.preventDefault();
				setMobToggle(!mobToggle);
			}}><FaBars /></div>
			<div className={`mob-returner${mobToggle ? ' mb-active' : 'mb-inactive'}`} onClick={(e) => {
				e.preventDefault();
				setMobToggle(false);
			}}></div>
		</header>
	);
};

export default Navbar;
