import React from 'react';
import ImageLoader from '../../misc-components/ImageLoader/ImageLoader';

import './PortfolioGridItem.css';

const PortfolioGridItem = ({
	category,
	item,
	image,
	imageInfo,
	viewIndex,
	setViewIndex }) => {

	/*
		Takes props for alt text info, actual subfolder, and filename for each thumbnail

		REFACTOR TODO: replace all images with a component that switches between a spinner and the actual image once load finishes (GRID ITEM, COMPARE)
	*/
	const src = `../portfolio-images/${category}/${item}/thumbs/${image}.jpg`;
	const newView = (e) => {
		e.preventDefault();
		setViewIndex(viewIndex);
	};

	return (
		<div className='p-grid-item' onClick={newView}>
			<ImageLoader
				src={src}
				alt={imageInfo}
				className='p-grid-img' />
			<strong>VIEW DETAILS</strong>
		</div>
	);
};

export default PortfolioGridItem;
