import { useState, useEffect, useRef } from 'react';
import { FaSpinner, FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { postUserRequest, inputValidator } from '../../lib';

import './ContactForm.css';

const ContactForm = () => {
	// Init refs
	const nameRef = useRef(null);
	const emailRef = useRef(null);
	const phoneRef = useRef(null);
	const addressRef = useRef(null);
	const cityRef = useRef(null);
	const detailsRef = useRef(null);
	const birthdayRef = useRef(null);

	/*
		True = inputs DISABLED, form-div 0 OPACITY
		False = inputs ENABLED, form-div 100 OPACITY
	*/
	const [disableInput, setDisableInput] = useState(false);

	// Controls if either form or results are in document flow (mostly styling)
	const [formFadeIn, setFormFadeIn] = useState(true);
	const [formElementVisible, setFormElementVisible] = useState(true);

	// Controls whether the button to return to the form and active inputs
	const [allowReturnToFormElement, setAllowReturnToFormElement] = useState(false);

	// Controls whether API response is received / boolean
	const [resultsFadeIn, setResultsFadeIn] = useState(false);
	const [resultsSucFail, setResultsSucFail] = useState(null);

	// Init state for server reponse msg
	const [responseMsg, setResponseMsg] = useState(null);

	/*
		ACCESS V2 ReCAPTCHA THROUGH window.grecaptcha, assuming the render works
	*/

	useEffect(() => {
		/*
			On component load, assumes that ReCAPTCHA is async called on page load from HTML head script
			Tries loading the v2 checkbox widget every 10 frames at 60 fps (160 ms)

			Only tries a few times before alerting the user that the connection isn't working, using browser alert
		*/
		const reRenderInterval = setInterval(() => {
			try {
				window.grecaptcha.render('twoTarget', {
					'sitekey': process.env.REACT_APP_VTWO_KEY,
					'size': 'normal',
					'error-callback': () => {
						return;
					}
				});
				clearInterval(reRenderInterval);
				clearTimeout(reRenderTimeout);
			} catch (error) {
				// Catch grecaptcha render error silently because of either React DOM render or network issues
				// console.log('captcha load loop')
			};
		}, 1000);
		const reRenderTimeout = setTimeout(() => {
			clearInterval(reRenderInterval);
			alert('Your browser is having trouble reaching ReCAPTCHA. Please resfresh the page.');
		}, 10000);
	}, []);

	const whiteSpaceRemover = (str) => {
		// Removes spammed whitespace
		// Can't be nested inside the regex function because I dont' think setState has access to it? closures or something
		return str.trim()
			.replaceAll(/ {2,}/g, ' ')
			.replaceAll(/\n{3,}/g, '\n\n')
			.replaceAll(/ ?' ?/g, '"')
			.replaceAll(/ ?- ?/g, '-', '')
			.replaceAll(/ \./g, '.')
			.replaceAll(/ ,/g, ',');
	};

	/* Reset button onClick handler */
	const returnForm = () => {
		// Instantly disable return function
		setAllowReturnToFormElement(false);
		// Start animations
		setResultsFadeIn(false);
		setFormFadeIn(true);
		setTimeout(() => {
			setResultsSucFail(null);
			setFormElementVisible(true);
			setDisableInput(true);
			setResponseMsg(null);
		}, 167);
	};

	const requester = async (input) => {
		// Instantly disable input
		setDisableInput(false);
		// Start animations
		setFormFadeIn(false);
		setResultsFadeIn(true);
		setTimeout(() => {
			setFormElementVisible(false);
			window.grecaptcha.reset();
		}, 167);

		const [twoToken, threeToken] = await Promise.all([
			window.grecaptcha.getResponse(),
			window.grecaptcha.execute(
				process.env.REACT_APP_VTHREE_KEY,
				{ action: 'hp_contact_form_submit' }
			)
		]);
		input['twoToken'] = twoToken;
		input['threeToken'] = threeToken;

		const response = await postUserRequest(input)
		setResponseMsg(response.message);
		setResultsSucFail(response.success);
		setAllowReturnToFormElement(true);
	};

	const submitHandler = (e) => {
		e.preventDefault();

		const name = whiteSpaceRemover(nameRef.current.value);
		const email = whiteSpaceRemover(emailRef.current.value);
		const phone = whiteSpaceRemover(phoneRef.current.value);
		const address = whiteSpaceRemover(addressRef.current.value);
		const city = whiteSpaceRemover(cityRef.current.value);
		const birthday = whiteSpaceRemover(birthdayRef.current.value);
		const details = detailsRef.current.value.trim();

		nameRef.current.value = name;
		emailRef.current.value = email;
		phoneRef.current.value = phone;
		addressRef.current.value = address;
		cityRef.current.value = city;
		birthdayRef.current.value = birthday;
		detailsRef.current.value = details;

		const input = {
			name, email, phone, address, city, birthday, details
		};

		const regCheck = inputValidator(input);
		if (regCheck.success === false) {
			let change = [];
			Object.keys(regCheck['bools']).forEach(key => {
				if (key === false) {
					change.push(key);
				};
			});
			alert(`Please check your ${change.join(', ')}`);
		} else {
			requester(input);
		};
	};

	const formClasser = `contact-form ${formFadeIn ? 'contact-form-active' : 'contact-form-inactive'}${formElementVisible ? '' : ' nodisplay'}`;
	const responseClasser = `review-output ${formElementVisible ? 'nodisplay' : ''} ${resultsFadeIn ? 'contact-form-active' : 'contact-form-inactive'}`;

	return (
		<section className='contact-form-section'>
			<h3>Let's get in touch:</h3>
			<form
				className={formClasser}
				hidden={!formElementVisible}
				onSubmit={submitHandler}>
				<fieldset disabled={disableInput}>
					<label
						htmlFor='name'
						className='form-label'>
						Name:
						<input
							type='text'
							id='name'
							name='name'
							className='contact-input'
							minLength='2'
							maxLength='120'
							autoComplete='name'
							required
							pattern="^ *([a-zA-Z., '-]){2,120}$"
							ref={nameRef} />
						<p className='input-hint'>Allowed: letters, spaces, = - ' , .</p>
					</label>
					<label
						htmlFor='email'
						className='form-label'>
						Email:
						<input
							type='email'
							id='email'
							name='email'
							className='contact-input'
							minLength='6'
							maxLength='319'
							autoComplete='email'
							required
							pattern='^ *[a-zA-Z0-9]+((\.|\+|_|-|/)[a-zA-Z0-9]+)*@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,} *$'
							ref={emailRef} />
						<p className='input-hint'>Example: john.smith01@example.com</p>
					</label>
					<label
						htmlFor='phone'
						className='form-label'>
						Phone:
						<input
							type='tel'
							id='phone'
							name='phone'
							className='contact-input'
							minLength='7'
							maxLength='14'
							autoComplete='tel'
							required
							pattern='^ *(\(?[0-9]{3}\)?)?[ -]?[0-9]{3}[ -]?[0-9]{4} *$'
							ref={phoneRef} />
						<p className='input-hint'>Example: (123) 456-7890</p>
					</label>
					<label
						htmlFor='address'
						className='form-label'>
						Address:
						<input
							type='text'
							id='address'
							name='address'
							className='contact-input'
							minLength='5'
							maxLength='120'
							autoComplete='street-address'
							required
							pattern="^ *[0-9]{1,6} ?([0-9a-zA-Z .,'-]){1, 118}[a-zA-Z0-9. ]$"
							ref={addressRef} />
						<p className='input-hint'>Example: 123 W. Sherman Way, Apt. 223</p>
					</label>
					<label
						htmlFor='city'
						className='form-label'>
						City:
						<input
							type='text'
							id='city'
							name='city'
							className='contact-input'
							minLength='2'
							maxLength='40'
							autoComplete='address-level2'
							required pattern="^[a-zA-Z ,.'-]{2, 40}$"
							ref={cityRef} />
					</label>
					<label
						htmlFor='birthday'
						className='form-label'
						style={{ display: 'none' }}>
						Birthday:
						<input
							type='date'
							id='birthday'
							name='birthday'
							className='contact-input'
							minLength='10'
							maxLength='10'
							birthday={birthdayRef} />
					</label>
					<label
						htmlFor='details'
						className='details-label'>
						How can we help?
						<textarea
							name='details'
							id='details'
							className='details-input'
							minLength='2'
							maxLength='800'
							rows='5'
							cols='80'
							required
							ref={detailsRef} />
					</label>
					<div id='twoTarget' />
					<p className='re-disclaimer'>This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy'>Privacy Policy</a> and <a href='https://policies.google.com/terms'>Terms of Service</a> apply.</p>
					<button type='submit'>Submit</button>
				</fieldset >
			</form >
			<div
				className={`review-output ${formElementVisible ? 'nodisplay' : ''} ${resultsFadeIn ? 'contact-form-active' : 'contact-form-inactive'}`}
				hidden={!formElementVisible}>
				<ul>
					<li>
						<p>Your name:
							{
								(nameRef.current !== null && nameRef.current !== undefined) &&
								nameRef.current.value
							}
						</p>
					</li>
					<li>
						<p>Your email:
							{
								(emailRef.current !== null && emailRef.current !== undefined) &&
								emailRef.current.value
							}
						</p>
					</li>
					<li>
						<p>Your phone:
							{
								(phoneRef.current !== null && phoneRef.current !== undefined) &&
								phoneRef.current.value
							}
						</p>
					</li>
					<li>
						<p>Your address:
							{
								(addressRef.current !== null && addressRef.current !== undefined) &&
								addressRef.current.value
							}
						</p>
					</li>
					<li>
						<p>Your city:
							{
								(cityRef.current !== null && cityRef.current !== undefined) &&
								cityRef.current.value
							}
						</p>
					</li>
					<li>
						<p>Your request:
							{
								(detailsRef.current !== null && detailsRef.current !== undefined) &&
								detailsRef.current.value
							}
						</p>
					</li>
				</ul>
				<div className={responseClasser}>
					{
						resultsSucFail === null ?
							<FaSpinner /> :
							resultsSucFail === true ?
								<FaCheckCircle /> :
								<FaExclamationCircle />
					}
					<p>
						{
							resultsSucFail !== null && responseMsg !== '' ?
								responseMsg : 'Server response pending...'
						}
					</p>
				</div>
				<button
					onClick={returnForm}
					hidden={resultsSucFail !== null ? resultsSucFail : true}
					disabled={!allowReturnToFormElement}>
					Return
				</button>
			</div>
		</section>
	);
};

export default ContactForm;
