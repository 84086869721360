import { BackButton } from '../components';

import './Error.css';

const Error = () => {
	return (
		<div className='error-comp home-error'>
			<p>Oops! There was an error!</p>
			<BackButton bbClass='home-error-bb' bbTarget='/' bbTextTarget='home'/>
		</div>
	);
};

export default Error;
