import { HeroLogo } from '../../../assets';

import './Hero.css';

const Hero = () => {

	/*
		Will probably want to make this a little more dynamic soon. A rotating cast of photos or something, it's pretty boring for a first imrpession
	*/

	return (
		<section id='home'>
			<img src={HeroLogo} alt='' className='hero-logo' />
			<p>We are committed to serving homeowners across Los Angeles in constructing the highest-quality homes of their dreams. We take pride in our work by ensuring that every project is completed efficently, safely, and promptly.</p>
		</section>
	);
};

export default Hero;
