import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { ContactForm } from '../../../components';

import './Contact.css';

const Contact = () => {

	/*
		CHECK CONTACTFORM COMMENTS
	*/

	return (
		<section id="contact">
			<h2>Need a job done?</h2>
			<ContactForm />
			<address className="info-grid">
				<h3>Or contact us directly:</h3>
				<div className="static-info">
					<FaEnvelope />
					<a href="mailto:contact@blockbuildersgc.com">contact@blockbuildersgc.com</a>
				</div>
				<div className="static-info">
					<FaPhone />
					<div>
						<p>English: (818) 470-8555</p>
						<p>Espa&ntilde;ol: (818) 826-6121</p>
					</div>
				</div>
			</address>
		</section>
	);
};

export default Contact;
