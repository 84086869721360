const postUserRequest = async ({
	name,
	email,
	phone,
	address,
	city,
	birthday,
	details,
	twoToken,
	threeToken }) => {
	/*
		Responsible for abstracting and simplifying the fetch logic
	*/
	try {
		const response = await fetch(process.env.REACT_APP_EMSUB, {
			method: 'POST',
			headers: { 'Content-type': 'application/json' },
			body: JSON.stringify({
				name: name,
				email: email,
				phone: phone,
				address: address,
				city: city,
				birthday: birthday,
				details: details,
				twoToken: twoToken,
				threeToken: threeToken
			}, (key, value) => {
				if (key === 'details') {
					/*
					Details sanitization cuz why not
					let ampRep = value.replaceAll("&", "&#38;")
					let quoteRep = ampRep.replaceAll('"', "&#34;");
					let ltRep = quoteRep.replaceAll("<", "&#60;");
					let gtRep = ltRep.replaceAll(">", "&#62;");
					*/
					return value.replaceAll('&', '&#38;')
						.replaceAll(':', '&#58;')
						.replaceAll('"', '&#34;')
						.replaceAll('<', '&#60;')
						.replaceAll('>', '&#62;');
				} else {
					return value;
				};
			})
		});
		if (response.status === 200) {
			const data = await response.json();
			return {
				message: `Your request id is ${data['id']}. ${data['message']}`,
				success: true
			};
		} else if (response.status === 429) {
			return {
				message: "You've made too many requests. Wait 5 minutes before resubmitting.",
				success: false
			};
		} else {
			return {
				message: 'There was an error with our servers. Try resubmitting. If the error persists, copy and paste your request details if necessary, then refresh the page and resubmit.',
				success: false
			};
		};
	} catch (error) {
		return {
			message: 'There was a connection problem to our servers. Copy your details if necessary, then refresh the page and resubmit.',
			success: false
		};
	};
};

const inputValidator = ({ name, email, phone, address, city, details }) => {

	/*
		Giant regex checker for every field
		Returns global bool then individual bools per regex test
	*/

	const nameChecker = (str) => {
		/*
		Makes sure the name:
			Starts and with a alpha char
			Ends with a period or alpha char
		*/
		const re = /^[a-zA-Z][a-zA-Z., -']{0,118}[a-zA-Z.]$/;
		return re.test(str);
	};

	const emailChecker = (str) => {
		/*
		The following regex was copy-pasted from the very helpful https://emailregex.com

		Local part (before the "@"):
			a. a string of any characters but enclosed in double quotes
			b. a string of any alphanumeric character
				b1. dots are allowed as long as there are alphanumeric chars between the dot and @

		domain part (after the "@"):
			a. an ip address enclosed in square brackets
			b. a domain containing alphanumeric chars followed by dots that are followed by alphanumeric
		*/
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(str);
	};

	const phoneChecker = (str) => {
		/*
		The string has to have either 7 or 10 digits (area code is optional)
		Spaces and hyphens are also optional
		*/
		const re = /^(\(?[0-9]{3}\)?)?[ -]?[0-9]{3}[ -]?[0-9]{4}$/;
		return re.test(str);
	};

	const addressChecker = (str) => {
		/*
		Must start with building number
		Must end with either an alphanum or period
		*/
		const re = /^[0-9]{1,6} ?([0-9a-zA-Z .,'-]){1,118}[a-zA-Z0-9.]$/;
		return re.test(str);
	};

	const cityChecker = (str) => {
		/*
		Must start and end with alphanum char
		No non alphanum char can be floating (must be preceded by alphanum char)
		*/
		const re = /^[a-zA-Z][a-zA-Z ,.'-]{0,38}[a-zA-Z]$/;
		return re.test(str);
	};

	const detailsChecker = (str) => {
		/*
		Since there are so many possible areas for errors, details will just be sanitized and passed as is
		*/
		const re = /^(([A-Za-z0-9 !#$%&*()-+=;:'",.<>/?])|\n|\t){2,4000}$/;
		return re.test(str);
	};

	// Init return flag
	let gregflag = true;

	const bools = {
		'name': nameChecker(name),
		'email': emailChecker(email),
		'phone': phoneChecker(phone),
		'address': addressChecker(address),
		'city': cityChecker(city),
		'details': detailsChecker(details)
	};

	Object.keys(bools).forEach(key => {
		if (bools[key] === false) {
			gregflag = false
		}
	});

	return {
		'success': gregflag,
		'bools': bools
	};
};

export {
	postUserRequest,
	inputValidator
};
