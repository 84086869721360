import './Footer.css';

const Footer = () => {

	/*
		Definitely needs work. TODO:
			> Get my own website up and replace with link
			> Copyright
			> etc.
	*/

	const version = '4.8.1';
	const date = new Date();

	return (
		<footer>
			<p>v{version}</p>
			<p>Site by <a href='https://www.nicvo.dev'>Nicolas Vo</a></p>
			<p>Copyright &copy; {date.getFullYear()} Block Builders General Contracting, Inc. All rights reserved.</p>
		</footer>
	);
};

export default Footer;
