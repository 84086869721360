import { useState, useMemo, useEffect } from 'react';
import { FaRegTimesCircle, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ImageLoader from '../../misc-components/ImageLoader/ImageLoader';
import { getActiveImages } from '../../../lib';

import './PortfolioViewer.css';

const PortfolioViewer = ({
	viewerIndex,
	pagedSet,
	sortedKeys,
	category,
	deactivate }) => {

	/*
		Fullscreen viewer takes item index prop to grab info and
		active images from data store. The returner elements pass
		an int -1 back to the parent grid to exit up the portfolio viewer
		z-index magic to make everything but the returners not trigger the -1 onClick

		The compare component handles the image
		display and potential toggle functionality
		CHECK PORTFOLIOCOMPARE COMMENTS
	*/
	const [itemIndex, setItemIndex] = useState(viewerIndex);
	const [imageIndex, setImageIndex] = useState(0);

	const activeImages = useMemo(() => {
		return getActiveImages({
			category: category,
			itemIndex: sortedKeys[itemIndex]
		});
	}, [itemIndex]);

	// Handlers for switching between items within a category

	const incItemIndexHandler = () => {
		if (itemIndex < sortedKeys.length - 1) {
			setItemIndex(itemIndex + 1);
			setImageIndex(0);
		};
	};

	const decItemIndexHandler = () => {
		if (itemIndex > 0) {
			setItemIndex(itemIndex - 1);
			setImageIndex(0);
		};
	};

	// Handlers for switching between images within an item

	const incImageIndexHandler = () => {
		if (imageIndex < activeImages.length - 1) { setImageIndex(imageIndex + 1) };
	};
	const decImageIndexHandler = () => {
		if (imageIndex > 0) { setImageIndex(imageIndex - 1) };
	};

	// Always focus viewer (hopefully when mounted)
	useEffect(() => {
		document.getElementById('viewer').focus();
	});

	// Keyboard functionality
	const keyDownHandler = ({ key }) => {
		switch (key) {
			case 'ArrowRight':
				console.log(key);
				incItemIndexHandler();
				break;
			case 'ArrowLeft':
				console.log(key);
				decItemIndexHandler();
				break;
			case 'Escape':
				console.log(key);
				deactivate(-1);
				break;
			default:
				break;
		};
	};

	const src = `../portfolio-images/${category}/${sortedKeys[itemIndex]}/fulls/${imageIndex + 1}.jpg`;
	const alt = pagedSet[sortedKeys[itemIndex]].images[activeImages[imageIndex]].text;

	return (
		<div className='p-view' tabIndex='0' onKeyDown={keyDownHandler} id='viewer'>
			<button
				className='item-ind'
				onClick={decItemIndexHandler}
				disabled={itemIndex > 0 ? false : true}>
				<FaAngleLeft />
			</button>
			<figure className='p-view-figure'>
				<button className='image-ind ind-dec'
					onClick={decImageIndexHandler}
					disabled={imageIndex > 0 ? false : true}>
					<FaAngleLeft />
				</button>
				<ImageLoader
					key={`full-${itemIndex}-${imageIndex}`}
					src={src}
					alt={alt}
					className='p-view-img' />
				<figcaption className='p-view-figcap'>
					<p>{alt}</p>
				</figcaption>
				<div
					className='inner-returner'
					tabIndex={0}
					onClick={() => { deactivate(-1); }}>
					<FaRegTimesCircle />
				</div>
				<div className='outer-returner' onClick={() => { deactivate(-1); }} />
				<button
					className='image-ind ind-inc'
					onClick={incImageIndexHandler}
					disabled={imageIndex < activeImages.length - 1 ? false : true}>
					<FaAngleRight />
				</button>
			</figure>
			<button
				className='item-ind'
				onClick={incItemIndexHandler}
				disabled={itemIndex < sortedKeys.length - 1 ? false : true}
			><FaAngleRight />
			</button>
		</div>
	);
};

export default PortfolioViewer;
