import React from 'react'
import { Link } from 'react-router-dom';
import { SmallIcon } from '../../../assets';
import { FaAngleLeft } from 'react-icons/fa';

import './BackButton.css';

const BackButton = ({
	bbTarget,
	bbClass,
	bbTextTarget }) => {
	/*
		Possibly unnecessary back button that's not used often
		Takes props for each target, the text within the link, and className
		props className for <Link> wrapper with no classNames within <Link> allow for specific styling based on context
	*/
	return (
		<>
			<Link to={bbTarget} className={`bb ${bbClass}`} >
				<img src={SmallIcon} alt='Small BBGC Icon' />
				<p><FaAngleLeft />Return to {bbTextTarget}</p>
			</Link>
		</>
	);
};

export default BackButton;
