import { Route, Routes } from 'react-router-dom';
import { Footer } from './containers/homepage-containers';
import { Homepage, Portfolio, Error } from './containers';
import { PortfolioBrowse, PortfolioError } from './containers/portfolio-containers';
import { PortfolioOutput } from './components';

import './App.css';

const App = () => {
	return (
		<>
			<Routes>
				<Route path='/' element={<Homepage />} />
				<Route path='portfolio' element={<Portfolio />}>
					<Route path='' element={<PortfolioBrowse />} />
					<Route path='bathroom' element={
						<PortfolioOutput
							category='Bathroom'
							key='bathroom-output' />} />
					<Route path='interior' element={
						<PortfolioOutput
							category='Interior'
							key='interior-output' />} />
					<Route path='exterior' element={
						<PortfolioOutput
							category='Exterior'
							key='exterior-output' />} />
					<Route path='*' element={<PortfolioError />} />
				</Route>
				<Route path='*' element={<Error />} />
			</Routes>
			<Footer />
		</>
	);
};

export default App;
