import { Link } from 'react-router-dom';
import { getServiceImgInfo } from '../../../lib';

import './Services.css';

const Services = () => {

	// Get the thumbs for each category from the JSON
	const { bathroom, interior, exterior } = getServiceImgInfo();

	return (
		<section id='services'>
			<h2>What we do:</h2>
			<div className='services-grid'>
				<Link to='/portfolio/bathroom' className='s-link'>
					<img src={bathroom.path} alt={bathroom.alt} />
					<div className='s-link-text'>
						<strong>BATHROOMS</strong><p>see more...</p>
					</div>
				</Link>
				<Link to='/portfolio/interior' className='s-link'>
					<img src={interior.path} alt={interior.alt} />
					<div className='s-link-text'>
						<strong>INTERIORS</strong><p>see more...</p>
					</div>
				</Link>
				<Link to='/portfolio/exterior' className='s-link'>
					<img src={exterior.path} alt={exterior.alt} />
					<div className='s-link-text'>
						<strong>EXTERIORS</strong><p>see more...</p>
					</div>
				</Link>
				<Link to='/portfolio' className='portfolio-link'>
					<strong>MORE PROJECTS</strong>
				</Link>
			</div>
		</section>
	);
};

export default Services;
