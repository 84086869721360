import { FaInstagram, FaYelp, FaFacebook } from 'react-icons/fa';
import { CSLBLogo } from '../../../assets';
import { ReviewCarousel } from '../../../components';

import './Reviews.css';

const Reviews = () => {

	/*
		CHECK REVIEW CAROUESL COMPONENT COMMENTS
	*/

	return (
		<section id='reviews' className='reviews'>
			<h2>What Yelp says:</h2>
			<ReviewCarousel />
			<h3>You can find us on:</h3>
			<ul>
				<li className='reviews-list-item'>
					{/*eslint-disable-next-line*/}
					<a href='https://www.yelp.com/biz/block-builders-general-contracting-san-fernando-valley'
						target='_blank'
						rel='external noopener'>
						<FaYelp />
					</a>
				</li>
				<li className='reviews-list-item'>
					{/*eslint-disable-next-line*/}
					<a href='https://www.homeadvisor.com/rated.BLOCKBUILDERSGENERAL.122927709.html'
						target='_blank'
						rel='external noopener'>
						<svg
							viewBox='0 0 140 100'
							height='1em'
							width='1em'
							xmlns='http://www.w3.org/2000/svg'>
							<g transform='matrix(.458295 0 0 .458295 43.5209 43.4922)'>
								<path d='M7.6-64.7H-23L-33.4,0.5c-0.2,0-0.4,0-0.7,0.1c-23.1,3.1-41.1,22.5-40.9,44.3c0.1,9.1,3.2,16.2,8.7,22c4.9,5.2,11.7,8.1,18.8,8.1c2.7,0,5.5-0.4,8-1.3c12.5-4.3,16.6-14.8,18.5-24.3v0c0.5-10.7-5.1-21.2-16-27.5l-3.4,21.7c-1.1,6.2-2.5,9.9-5.6,10.9c-1.8,0.7-4.1,0-5.6-1.6c-2-2.1-3.1-4.4-3.2-8.3c-0.1-10.9,8.5-19.4,17.9-22.7c3-1.1,6.3-1.6,9.5-1.6c3.6,0,7.2,0.7,10.5,1.8c12.8,3,20.5,16.5,22.4,31.2l1.1,8.2h21.2L7.6-64.7z M-14.1,1.9L-8-44.7h0.7l6.8,52.1C-4.8,5-9.4,3.2-14.1,1.9L-14.1,1.9z M189.4-29.5v91h-21.2v-91L189.4-29.5z M127.6,93.1c27.8,0,30-15.8,30-38.2l0.2-84.4h-11c-3.9,0-8.8,3-9.1,6.8l-0.3,2.9l-1-2.8c-2.1-5.5-7.2-8.4-14.9-8.4s-13.4,3-17,8.8c-3.4,5.7-4.2,13-4.2,18.2v35.4c0,8.8,1.3,15.3,3.9,19.8c3.2,5.5,8.4,8.2,15.9,8.2c7.1,0,12.4-3,15.1-8.5l1.3-2.6v13c0,8.9-0.5,15.2-7.7,15.2c-1.6,0-6.9-0.5-6.9-7.7v-0.3h-21.4V69c0,6.9,1.9,12.5,5.6,16.6C110.7,90.6,117.9,93.1,127.6,93.1L127.6,93.1z M136.2,27.8c0,8.7-1.2,13.4-7.7,13.4c-6.1,0-6.9-5.8-6.9-11.5V-2.1c0-7.2,2.6-10.6,7.8-10.6c5.9,0,6.8,5.8,6.8,11.5V27.8z M190.5-49.5c0,6.5-5.1,11.5-11.7,11.5c-6.3,0.1-11.4-5-11.5-11.3v-0.3c0-6.5,5.1-11.7,11.5-11.7C185.3-61.2,190.5-55.9,190.5-49.5z M55.8-0.1v61.6H34.5l-0.2-91h11.9c4.1,0,8.8,2.9,9.3,7.2l0.2,2l1-1.7c3.4-5.9,9-8.9,16.6-8.9c12.1,0,18.3,7.8,18.3,23.3v69.2H70.4V-2.9c0-6.5-2.3-9.9-6.8-9.9C57.9-12.7,55.8-9.4,55.8-0.1z' /><path d='M-20.8,49.5c1.1-4.6,3.5-22.1,4-27.3c-3.4-1.2-6.9-1.8-10.5-1.8c-3.2,0-6.4,0.5-9.5,1.6C-26,28.2-20.3,38.7-20.8,49.5z' /></g>
						</svg>
					</a>
				</li>
				<li className='reviews-list-item'>
					{/*eslint-disable-next-line*/}
					<a href='https://www.instagram.com/blockbuildersgc'
						target='_blank'
						rel='external noopener'>
						<FaInstagram />
					</a>
				</li>
				<li className='reviews-list-item'>
					{/*eslint-disable-next-line*/}
					<a href='https://www.facebook.com/blockbuildersgc/'
						target='_blank'
						rel='external noopener'>
						<FaFacebook />
					</a>
				</li>
			</ul>
			<div className='cslb-grid'>
				<h2>License No. 1086377:</h2>
				{/*eslint-disable-next-line*/}
				<a href='https://www.cslb.ca.gov/onlineservices/checklicenseII/LicenseDetail.aspx?LicNum=1086377'
					target='_blank'
					rel='external noopener'
					className='cslb-link'>
					<img src={CSLBLogo} alt='CSLB logo' id='cslb-logo' />
				</a>
			</div>
		</section>
	);
};

export default Reviews;
