import { useState, useMemo } from 'react';
import PortfolioGrid from '../PortfolioGrid/PortfolioGrid.js';
import PortfolioViewer from '../PortfolioViewer/PortfolioViewer.js';
import PortOutputScrollToTop from '../../misc-components/ScrollToTop/PortOutputScrollToTop.js';
import { getCategoryText, getCategoryLength, getPagedSet } from '../../../lib/index.js';

import './PortfolioOutput.css';

const pagInt = 10;

const PortfolioOutput = ({ category }) => {
	// Get static text
	const text = useMemo(() => { return getCategoryText(category.toLowerCase()) }, [])
	// Get # of active items incategory
	const categoryLength = useMemo(() => { return getCategoryLength(category.toLowerCase()); }, [])
	// Init state for setting viewerIndex
	const [viewerIndex, setViewerIndex] = useState(-1);
	// Init handler for setting viewerIndex to pass to grid items
	const viewerHandler = (index) => { setViewerIndex(index); }
	// Init pagination state
	const [page, setPage] = useState(() => { return pagInt < categoryLength ? pagInt : categoryLength });
	const [morePage, setMorePage] = useState(() => {
		// Automatically disable paginator button if there's not a lot of images
		return page < categoryLength ? true : false;
	});

	// Return paged image data from JSON
	const pagedSet = useMemo(() => { return getPagedSet({ category: category.toLowerCase(), page: page }) }, [page]);
	// IMPORTANT: sorted keys are all items with active property set to 'true' in the JSON
	const sortedKeys = Object.keys(pagedSet).sort((a, b) => { return parseInt(a) > parseInt(b) });

	/*
		So basically each category in the portfolio will have its own little blurb going into detail about the services Jose provides for each of the larger categories
		Followed by a grid of relevant examples of work

		The thumbs can create a fullscreen viewer with onClick handlers passing the index of the clicked thumb back to the parent grid component
		Parent grid then conditionally renders the fullscreen viewer if the index is >= 0
		Disable viewer by passing a -1 as index back to parent grid
	*/

	// Handler for retrieving more pages
	const paginator = (e) => {
		e.preventDefault();
		if (page + pagInt >= categoryLength) {
			setPage(categoryLength);
			setMorePage(false);
		} else {
			setPage(page + pagInt);
		};
	};

	return (
		<main className='portfolio-main'>
			<h1>{category} Remodeling</h1>
			<section className='portfolio-blurb'>
				<p>{text.intro}</p>
				<ul>
					{
						text.taskList.map(
							(task) => { return <li key={`${task}-key`}>{task}</li> }
						)
					}
				</ul>
			</section>
			<PortfolioGrid
				category={category.toLowerCase()}
				pagedSet={pagedSet}
				sortedKeys={sortedKeys}
				viewerHandler={viewerHandler} />
			{
				// Only renders pagination button if morePage is true
				morePage && (<button onClick={paginator} className='paginator'>Load more</button>)
			}
			{
				// Renders instagram link once pagination ends
				!morePage && (
					<div className='pagination-ended'>
						<p>No more photos here. For the latest, check out the Block Builders <a href='https://www.instagram.com/blockbuildersgc' rel='external noopener'>Instagram!</a></p>
					</div>)
			}
			{
				// Renders Portfolio Viewer if a thumbnail is clicked
				viewerIndex >= 0 ? <PortfolioViewer
					key='viewer-key'
					category={category.toLowerCase()}
					sortedKeys={sortedKeys}
					pagedSet={pagedSet}
					viewerIndex={viewerIndex}
					deactivate={viewerHandler} /> : null
			}
			<PortOutputScrollToTop />
		</main>
	);
};

export default PortfolioOutput;
