import React from 'react';
import { FaSpinner } from 'react-icons/fa';

import './LoadSpinner.css';

const LoadSpinner = () => {

	/*
		Probably gonna implement this as part of an img container component later
	*/

	return (
		<div className='spinframe'>
			<FaSpinner />
		</div>
	);
};

export default LoadSpinner;
