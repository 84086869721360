import { Contact, Hero, Reviews, Services } from './homepage-containers';
import { Navbar } from '../components';

const Homepage = () => {
	return (
		<>
			<main>
				<Hero />
				<Navbar key='h-nav' />
				<Services />
				<Reviews />
				<Contact />
			</main>
		</>
	);
};

export default Homepage;
