import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

import './ReviewItem.css';

const ReviewItem = ({ review }) => {

	/*
		This one's kinda dirty, but takes a bunch of personal info about the person reviewing, including stars given
		Weird workaround for how to display stars:
			> For every int, push true to an arr
			> If a remainder, push a false
			> Add full star svg for each true, a half star for false
			> Works I guess
	*/

	const date = new Date(review.date);
	let stars = review.stars;
	let starArr = [];
	while (stars > 0) {
		if (stars >= 1) {
			starArr.push(true);
		} else {
			starArr.push(false);
		}
		stars -= 1;
	};

	return (
		<div className='review-item'>
			<div className='review-header'>
				<h3>{review.name}</h3>
				<p>{review.city}</p>
				<p>{date.toDateString()}</p>
			</div>
			<div className='review-content'>
				<div className='star-grid'>
					{
						starArr.map(
							(star, index) => {
								return star === true ?
									<FaStar key={`${review.name}-star-${index}`} />
									: <FaStarHalfAlt key={`${review.name}-halfstar`} />
							})
					}
				</div>
				<p>{review.text}</p>
			</div>
		</div>
	);
};

export default ReviewItem;
