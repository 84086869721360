import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteScrollToTop = () => {
	const { pathname } = useLocation();

	/*
		Used to get reset position between homepage and portfolio and, within portfolio, the different categories
	*/

	useEffect(() => {
		// if path is either home (explicit) or is within the portfolio, scroll to top
		if (pathname === '/' || pathname.match(/^\/portfolio/)) {
			const target = window.pageYOffset
			window.scrollTo({
				top: 0 - target,
				behavior: 'instant'
			});
		}
	}, [pathname]);

	return null;
};

export default RouteScrollToTop;
